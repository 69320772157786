<template>
    <div style="width: 100%;background: #fff;">
        <!--服务订单管理-->
        <div v-if="nowIndex == 0">
            <div class="right_header">
                <Row :gutter="16">
                    <Col span="5" style="display: flex;align-items: center">
                        <span style="flex-shrink: 0;">订单号：</span>
                        <Input style="width: 200px;" v-model="params.orderNo"/>
                    </Col>
                    <Col span="8" style="display: flex;align-items: center">
                        <span style="flex-shrink: 0;">订单日期：</span>
                        <Row :gutter="16">
                            <Col span="11">
                                <DatePicker style="width: 130px;" type="date" placeholder="开始时间" :transfer="true"
                                            :options="startOption" v-model="startTime"/>
                            </Col>
                            <Col span="2" style="display: flex;align-items: center">至</Col>
                            <Col span="11">
                                <DatePicker style="width: 130px;" :transfer="true" type="date" placeholder="结束时间"
                                            :options="endOption" v-model="endTime"/>
                            </Col>
                        </Row>
                    </Col>
                    <Col span="4" style="display: flex;align-items: center;">
                        <span style="flex-shrink: 0">订单状态：</span>
                        <Select v-model="params.orderState" :transfer="transfer">
                            <Option value="">全部</Option>
                            <Option v-for="(item,index) in orderStatuss" :key="index" :value="Object.keys(item)[0]">
                                {{ item[Object.keys(item)[0]] }}
                            </Option>
                        </Select>
                    </Col>
                    <Col span="7">
                        <div @click="search()" class="button_style"> 查询</div>
                    </Col>
                </Row>
            </div>
            <div class="right_real_content">
                <Table border :columns="columns12" :data="dataList" :disabled-hover="true">
                    <template slot-scope="{ row }" slot="name">
                        <strong>{{ row.name }}</strong>
                    </template>
                    <template slot-scope="{ row, index }" slot="action">
                        <div v-if="row.stateName == '待评价'">
                            <Button v-if="row.evaluateState == 0" type="primary" size="small"
                                    style="margin-right: 5px; color: #0E61DF" @click="comment(row)">评价
                            </Button>
                            <span class="operation_span"
                                  v-if="row.evaluateState == 0"
                            >|</span>
                            <Button type="text" size="small" @click="seeDetail(row)" style="color: #0E61DF">查看详情
                            </Button>
                        </div>
                        <div v-if="row.stateName == '未付款'">
                            <Button type="text" size="small" style="color: #0E61DF" @click="goPay(row)">去支付</Button>
                            <span class="operation_span">|</span>
                            <Button type="text" size="small" @click="seeDetail(row)" style="color: #0E61DF">查看详情
                            </Button>
                            <span class="operation_span">|</span>
                            <Button type="text" size="small" @click="removeOrder(row)" style="color: #0E61DF">取消订单
                            </Button>
                        </div>
                        <div v-if="row.stateName == '待审核'">
                            <!--              <Button-->
                            <!--                type="text"-->
                            <!--                size="small"-->
                            <!--                @click="removeOrder(row)"-->
                            <!--                style="color: #0E61DF"-->
                            <!--                >取消订单</Button>-->
                            <!--              <span class="operation_span">|</span>-->
                            <Button
                                    type="text"
                                    size="small"
                                    @click="seeDetail(row)"
                                    style="color: #0E61DF"
                            >查看详情
                            </Button
                            >
                        </div>
                        <div v-if="row.stateName == '已完成'">
                            <Button type="text" size="small" @click="seeDetail(row)" style="color: #0E61DF">查看详情
                            </Button>
                            <span class="operation_span" v-if="row.evaluateState == 0">|</span>
                            <Button v-if="row.evaluateState == 0" type="primary" size="small"
                                    style="margin-right: 5px; color: #0E61DF" @click="comment(row)">评价
                            </Button>
                        </div>
                        <div
                                v-if="row.stateName !='待评价' && row.stateName !='未付款' && row.stateName !='待审核' && row.stateName !='已完成' ">
                            <Button type="text" size="small" @click="seeDetail(row)" style="color: #0E61DF">查看详情
                            </Button>
                        </div>
                    </template>
                </Table>
                <Page
                        :total="total"
                        show-elevator
                        style="margin-top: 50px; float: right; margin-bottom: 20px"
                        @on-change="changePage"
                />
            </div>
            <div v-html="payForm"></div>
        </div>
        <!--服务订单详情-->
        <div v-if="nowIndex == 2">
            <div
                    style="
          height: auto;
          border: 1px solid #dae2e5;
          border-bottom: none;
          border-left: none;
          border-right: none;
        "
            >
                <div
                        style="
            height: 70px;
            padding-left: 20px;
            line-height: 70px;
            font-size: 20px;
            background-color: #f3fcfc;
            color: black;
          "
                >
                    订单详情
                </div>
                <div style="padding-bottom: 30px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >基本信息</span
          >
                    <div class="div_center" style="border-bottom: none">
                        <Row :gutter="16">
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单号</span
                      >：{{ detailItem.no }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单日期</span
                      >：{{ detailItem.updatedTime }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单状态</span
                      >：{{ detailItem.stateName }}
                                        </div>
                                        <div
                                                v-if="detailItem.type === '支付宝'"
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：支付宝
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type === '微信'"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：微信
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                                v-if="detailItem.type === '线下'"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：线下
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >交易单号</span
                      >：{{ detailItem.tradeNo }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >会员名称</span
                      >：{{ detailItem.buyer }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >企业名称</span
                      >：{{ detailItem.buyerMember }}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span="12">
                                <Row>
                                    <Col span="24">
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px;      text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow:hidden;"
                                        >
                      <span
                              :title="detailItem.remark"
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >买家留言</span
                      >：{{ detailItem.remark || "无" }}
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >服务质量</span
                      >：
                                            <Rate disabled v-model="detailItem.orderEvaluate.serviceQuality"/>
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >服务态度</span
                      >：
                                            <Rate disabled v-model="detailItem.orderEvaluate.serviceAttitude"/>
                                        </div>
                                        <div
                                                style="height: 40px; line-height: 40px; font-size: 14px"
                                        >
                      <span
                              style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >响应速度</span
                      >：
                                            <Rate disabled v-model="detailItem.orderEvaluate.serviceSpeed"/>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 400px" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >商品清单
          </span>
                    <Table
                            border
                            :columns="detailColumn"
                            :data="detailData"
                            :disabled-hover="true"
                    >
                        <template slot-scope="{ row }" slot="name">
                            <strong>{{ row.name }}</strong>
                        </template>
                    </Table>
                </div>

                <div style="height: auto;padding-bottom: 30px; border-bottom: none" class="div_center">
          <span
                  style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >发票信息</span
          >
                    <div v-if="!qrOrSelf">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                发票抬头：<span>{{ detailItem.invoiceHeader }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：<span>{{ detailItem.invoiceMailingAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="qrOrSelf">
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业名称：<span>{{ detailItem.invoiceHeader }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                公司地址：<span>{{ detailItem.companyAddress }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业税号：<span>{{ detailItem.taxpayerNumber }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票邮寄地址：{{ detailItem.invoiceMailingAddress }}<span></span>
                            </div>
                        </div>
                        <div>
                            <div
                                    class="liang_hang_sheng"
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                height: 40px;
                line-height:20px ;

              "
                            >
                                企业开户行：<span>{{ detailItem.businessBank }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人姓名：<span>{{ detailItem.invoiceRecipientName }}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                    style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;
              "
                                    class="liang_hang_sheng"
                            >
                                企业银行开行账号：<span>{{ detailItem.businessBankNo }}</span>
                            </div>
                            <div style="display: inline-block;width: 300px; height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                                发票收件人联系方式：{{ detailItem.invoiceRecipientPhone }}<span></span>
                            </div>
                        </div>
                        <div style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
                width: 300px;
                 height: 40px;
                line-height:20px ;" class="liang_hang_sheng">
                            公司电话：<span>{{ detailItem.businessPhone }}</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div v-html="payForm"></div>
        <div
                class="center"
                style="background-color: white; padding: 40px 0"
                v-if="nowIndex == 1"
        >
            <div
                    style="
          font-size: 24px;
          width: 100%;
          text-align: center;
          margin-bottom: 45px;
        "
            >
                您的订单已完成支付，请等待后台运营人员审核！
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                支付金额：￥{{ priceSum }}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 15px">
                订单号：{{ orderNo }}
            </div>
            <div style="font-size: 14px; text-align: center; margin-bottom: 30px">
                支付方式：{{ payTypeTxt }}
            </div>
            <div style="width: 410px; margin: 0 auto">
                <div
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 255, 255);
            width: 178px;
            height: 38px;
            color: rgb(255, 106, 0);
            cursor: pointer;
            margin: 0 auto;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;
          "
                        @click="seeDetail2()"
                >
                    查看订单
                </div>
                <div
                        @click="openZoosUrl"
                        style="
            border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 106, 0);
            width: 178px;
            height: 38px;
            color: white;
            cursor: pointer;
          margin-left: 10px;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;
          "
                >
                    联系客服
                </div>
            </div>
        </div>
        <!--评价-->
        <Modal
                v-model="commentModal"
                title="评价"
                ok-text="提交"
                cancel-text="取消"
                class-name="vertical-center-modal"
        >
            <div style="height: 40px">
                <div
                        style="
            float: left;
            width: 20%;
            height: 40px;
            line-height: 35px;
            font-size: 14px;
          "
                >
                    服务质量:
                </div>
                <div
                        style="float: left; width: 80%; height: 40px; word-wrap: break-word"
                >
                    <Col span="12">
                        <Rate show-text v-model="detailComment.serviceQuality"/>
                    </Col>
                </div>
            </div>
            <div style="height: 40px">
                <div
                        style="
            float: left;
            width: 20%;
            height: 40px;
            line-height: 35px;
            font-size: 14px;
          "
                >
                    服务态度:
                </div>
                <div
                        style="float: left; width: 80%; height: 40px; word-wrap: break-word"
                >
                    <Col span="12">
                        <Rate show-text v-model="detailComment.serviceAttitude"/>
                    </Col>
                </div>
            </div>
            <div style="height: 40px">
                <div
                        style="
            float: left;
            width: 20%;
            height: 40px;
            line-height: 35px;
            font-size: 14px;
          "
                >
                    响应速度:
                </div>
                <div
                        style="float: left; width: 80%; height: 40px; word-wrap: break-word"
                >
                    <Col span="12">
                        <Rate show-text v-model="detailComment.serviceSpeed"/>
                    </Col>
                </div>
            </div>
            <div style="height: 120px; text-align: center" id="textarea">
                <Input
                        v-model="detailComment.evaluateContent"
                        type="textarea"
                        :autosize="{ minRows: 5, maxRows: 8 }"
                        placeholder="请输入5-150字符内的评价内容"
                ></Input>
            </div>
            <div style="text-align: center; margin-top: 10px">
                <div
                        class="button_style"
                        style="margin-left: 26px; width: 80px; position: relative; top: 50%"
                        @click="submit()"
                >
                    提交
                </div>
                <div
                        class="button_style"
                        style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
                        @click="confirmDel()"
                >
                    取消
                </div>
            </div>
        </Modal>

        <!--    // 取消订单-->
        <Modal
                v-model="removeOrderModal"
                title="取消专利订单信息"
                width="400"
                :footer-hide="footHide"
        >
            <div>
                <div style="padding: 20px">
                    <div
                            style="
              width: 95%;
              margin-left: 50%;
              transform: translateX(-50%);
              margin-top: 20px;
              text-align: center;
            "
                    >
                        <div style="margin-bottom: 30px">
                            <span>您确定取消该订单信息？</span>
                        </div>
                        <div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                "
                                    @click="notRemove()"
                            >
                                我再想想
                            </div>
                            <div
                                    class="button_style"
                                    style="
                  margin-left: 26px;
                  width: 80px;
                  position: relative;
                  top: 50%;
                  background-color: #fda736;
                "
                                    @click="confirmRemove()"
                            >
                                确定
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal
                v-model="payModal"
                title="微信支付"
                width="400"
                :footer-hide="footHide"
                @on-visible-change="watchPayModal"
        >
            <vue-qr :text="wechatUrl" :margin="0" :size="200"></vue-qr>
        </Modal>
        <Modal
                v-model="lineModal"
                title="上传凭证"
                width="400"
                :footer-hide="footHide"
        >
            <Form :model="lineForm">
                <FormItem label="上传支付凭证：">
                    <Upload
                            action=""
                            :before-upload="(file) => handleUpload(file)"
                            :multiple="footHide"
                    >
                        <Button>选择文件</Button>
                        {{ hasChooseFile }}
                    </Upload>
                </FormItem>
                <FormItem label="备注：">
                    <Input
                            v-model="lineForm.remarks"
                            :autosize="{minRows: 5,maxRow:10}"
                            type="textarea"
                    ></Input>
                </FormItem>
            </Form>
            <div>
                <div
                        class="button_style"
                        style="margin-left: 26px; width: 80px; position: relative; top: 50%"
                        @click="notUpload()"
                >
                    我再想想
                </div>
                <div
                        class="button_style"
                        style="
            margin-left: 26px;
            width: 80px;
            position: relative;
            top: 50%;
            background-color: #fda736;
          "
                        @click="confirmUpload()"
                >
                    确定
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
    import {get} from "@/plugins/http/http";
    import baseUrl from "@/plugins/http/baseUrl";
    import {
        getPatentOrderList,
        delPatentOrder,
        removePatentOrder,
        saveComment
    } from "@/plugins/api/EnterpriseManagementCenter";
    import {uploadVoucher} from "@/plugins/api/EnterpriseManagementCenter";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        errorToast
    } from "@/plugins/tools/util";
    import {uploadImg} from "@/plugins/api/sciencePolicy";

    export default {
        name: "ServiceOrder",
        watch: {
            startTime() {
                let self = this;
                this.endOption = {
                    disabledDate(date) {
                        if (self.startTime) {
                            return date && date.valueOf() < self.startTime;
                        }
                    }
                };
            },
            endTime() {
                let self = this;
                this.startOption = {
                    disabledDate(date) {
                        if (self.endTime) {
                            return date && date.valueOf() > self.endTime;
                        }
                    }
                };
            }
        },
        data() {
            return {
                qrOrSelf: true,
                clearable: false,
                transfer: true,
                orderStatuss: [

                    {EXAMINING: "待审核"},
                    {SIGNING: "待签约"},
                    {RECEIPTING: "待接单"},
                    {HANDLING: "待办理"},
                    {CHANGING: "待变更"},
                    {ARCHIVING: "待归档"},
                    {COMPLETED: "已完成"},
                    {CANCELLED: "已取消"},
                    {REFUNDING: "待退款"},
                    {REFUNDED: "已退款"}
                ],
                hasChooseFile: "未选择文件",
                lineForm: {
                    remarks: "",
                    paymentVoucher: "",
                    orderSn: ""
                },
                id: "",
                payTypeTxt: "",
                priceNum: "",
                detailRow: {},
                lineModal: false,
                payModal: false,
                removeItem: {},
                total: 10,
                valueDisabled: 2,
                params: {
                    pageNum: 1,
                    pageSize: 10,
                    orderType: "SERVICE",
                    orderNo: "",
                    orderState: "",
                    orderCreateFrom: "",
                    orderCreateTo: "",
                    isFront: true
                },
                nowIndex: 0,
                orderId: "",
                orderNo: "",
                orderType: "",
                startOption: {},
                endOption: {},
                startTime: "",
                payForm: "",
                endTime: "",
                commentItem: {},
                detailComment: {
                    serviceAttitude: 0,
                    serviceSpeed: 0,
                    serviceQuality: 0,
                    evaluateContent: "",
                    orderNo: ""
                },
                fwzl: 0,
                fwtd: 0,
                xysd: 0,
                desc: "",
                orderInfo: [{name: "11111", value: "122"}],
                footHide: false,
                removeOrderModal: false,
                commentModal: false,
                goodsData: [],
                detailItem: {},
                detailData: [],
                detailColumn: [
                    {
                        title: "店铺",
                        key: "shopName",
                        align: "center"
                    },
                    {
                        title: "商品名称",
                        key: "goods",
                        align: "center",
                        render: (h, params) => {
                            return h("div", [
                                h("span", {
                                    style: {
                                        display: "-webkit-box",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        "-webkit-line-clamp": "2",
                                        "-webkit-box-orient": "vertical"
                                    },
                                    domProps: {
                                        title: params.row.goods
                                    }
                                }, params.row.goods)
                            ]);
                        }
                    },
                    {
                        title: "服务类型",
                        key: "goodsClassify",
                        align: "center"
                    },
                    {
                        title: "商品价格",
                        key: "price",
                        align: "center",
                        render: (h, params) => (
                            < div > {params.row.price === "¥0" ? "面议" : params.row.price} < /div>
        )
        },
            {
                title: "规格",
                    key
            :
                "goodsSpec",
                    align
            :
                "center"
            }
        ,
            {
                title: "服务费",
                    key
            :
                "serviceCharge",
                    align
            :
                "center"
            }
        ,
            {
                title: "订单总额",
                    key
            :
                "totalPrice",
                    align
            :
                "center"
            }
        ],
            dataList: [],
                wechatUrl
        :
            "",
                parForm
        :
            {
            }
        ,
            columns12: [
                {
                    title: "订单号",
                    key: "no",
                    align: "center"
                },
                {
                    title: "商品名称",
                    key: "goodsName",
                    align: "center"
                },
                {
                    title: "店铺",
                    key: "shopName",
                    align: "center"
                },
                {
                    title: "订单日期",
                    key: "updatedTime",
                    align: "center"
                },
                {
                    title: "商品价格",
                    key: "price",
                    align: "center",
                    width: 100,
                    render: (h, params) => (
                        < div > {params.row.price === "¥0" ? "面议" : params.row.price} < /div>
        )
        },
            {
                title: "订单状态",
                    key
            :
                "stateName",
                    align
            :
                "center",
                    width
            :
                100
            }
        ,
            {
                title: "操作",
                    slot
            :
                "action",
                    width
            :
                180,
                    align
            :
                "center"
            }
        ]
        }
            ;
        },
        methods: {

            watchPayModal(status) {
                if (status == false) {
                    clearInterval(this.checkOrderTimer);
                }
            },
            openZoosUrl() {
                openZoosUrl("chatwin");
            },
            notUpload() {
                this.lineModal = false;
            },
            async confirmUpload() {
                let json = await uploadVoucher(this.lineForm);

                if (json.code == 0) {
                    this.lineForm.paymentVoucher = "";
                    this.lineForm.orderSn = "";
                    this.lineForm.remarks = "";
                    successToast("上传成功，等待客服确认");
                    this.lineModal = false;
                    this.getServiceOrder();
                } else {
                    errorToast(json.message);
                }
            },
            async handleUpload(file) {
                let that = this;
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                if (FileExt == "png" || FileExt == "jpg") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        code = e.target.result;

                        let data = {
                            imageBase64: code,
                            dataType: 0
                        };
                        uploadImg(data).then((res) => {
                            if (res.code == "200") {
                                that.lineForm.paymentVoucher = res.data;

                                successToast("上传成功");
                                this.hasChooseFile = "已选择";
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                } else {
                    warnToast("只支持jpg和png");
                    this.file = "";
                }

                // if(json.code == 200){

                // }
            },

            // 生成订单的查看
            seeDetail2() {
                get(`${baseUrl}/v1/order/detail/${this.id}`).then((res) => {
                    let item = res.result;
                    this.detailItem = res.result;
                    if (!this.detailItem.orderEvaluate) {
                        this.detailItem.orderEvaluate = {};
                        this.detailItem.orderEvaluate.serviceQuality = 0;
                        this.detailItem.orderEvaluate.serviceSpeed = 0;
                        this.detailItem.orderEvaluate.serviceAttitude = 0;
                    }
                    if (this.detailItem.businessBank || this.detailItem.businessBank == "") {
                        this.qrOrSelf = true;
                    } else {
                        this.qrOrSelf = false;
                    }
                    let detailData = {};
                    detailData.shopName = item.shopName;
                    detailData.goods = item.goods[0].goodsName;
                    detailData.goodsClassify = item.goods[0].goodsClassify;
                    detailData.price = "¥" + item.goods[0].price;
                    detailData.goodsSpec = item.goods[0].goodsSpec;
                    detailData.serviceCharge = "¥" + item.goods[0].serviceCharge;
                    detailData.totalPrice =
                        "¥" + (Number(item.goods[0].price) + Number(item.goods[0].serviceCharge));
                    this.detailData.push(detailData);
                    // this.$router.push({
                    //   path: "/enterprise/enterprise-management-center",
                    //   query: {
                    //     id: this.$route.query.id,
                    //   },
                    // });
                    this.nowIndex = 2;
                    scrollTo(0, 0);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;

                    this.$parent.changeHeight();
                });
            },
            qrcode(url) {
                new QRCode("wxQrcode", {
                    width: 500,
                    height: 500,
                    text: url,
                    colorDark: "#000",
                    colorLight: "#fff"
                });
            },

            // 搜寻
            async search() {
                if (this.startTime) {
                    this.params.orderCreateFrom = this.startTime.getTime();
                }
                if (this.endTime) {
                    this.params.orderCreateTo = this.endTime.getTime();
                }
                this.params.pageNum = 1;
                const json = await getPatentOrderList(this.params);
                if (json.code === 0) {
                    this.dataList = json.result.list;
                    this.dataList.forEach((item, index) => {
                        item.goodsName = item.goods[0].goodsName;
                        item.price = "¥" + item.goods[0].price;
                    });
                    this.total = json.result.total;
                    this.$parent.changeHeight();
                    this.params.orderCreateTo = "";
                    this.params.orderCreateFrom = "";
                } else {
                    this.dataList = [];
                }
            },
            // 翻页
            async changePage(index) {
                this.params.pageNum = index;
                const json = await getPatentOrderList(this.params);
                this.dataList = json.result.list;
                this.dataList.forEach((item, index) => {
                    item.goodsName = item.goods[0].goodsName;
                    item.price = "¥" + item.goods[0].price;
                });
                this.total = json.result.total;
                this.$parent.changeHeight();
            },
            //取消订单
            removeOrder(item) {
                this.removeItem = item;
                this.removeId = item.id;
                this.removeOrderModal = true;
            },
            notRemove() {
                this.removeOrderModal = false;
            },
            async confirmRemove() {
                let params = {
                    id: this.removeId
                };
                const json = await removePatentOrder(params);
                if (json.code === 0) {
                    successToast("取消成功");
                    this.getServiceOrder();
                } else {
                    errorToast(json.message);
                }
                // this.removeItem.stateName = "待退款";
                this.removeOrderModal = false;
                this.$parent.changeHeight();
            },

            // 去支付
            goPay(row) {
                this.lineForm.orderSn = row.no;
                this.orderNo = row.no;
                this.id = row.id;
                let orderId = row.id;
                get(`${baseUrl}/v1/pay/${orderId}`, {
                    notifyUrl: "",
                    returnUrl:
                        location.href + `&payStatus=1&orderId=${orderId}&payType=${row.type}`
                }).then((res) => {
                    if (res.code === 0) {
                        //this.shop = res.result.shop;
                        if (row.type === "ALIPAY") {
                            this.payTypeTxt = "支付宝支付";
                            this.payForm = res.result.form;
                            setTimeout(() => {
                                document.forms[0].submit();
                            });
                        }
                        if (row.type === "LINE") {
                            this.payTypeTxt = "线下支付";
                            this.lineModal = true;
                        }
                        if (row.type === "WECHAT") {
                            this.payTypeTxt = "微信支付";
                            this.payModal = true;
                            this.wechatUrl = res.result.params.code_url;
                            this.loopCheckOrder(orderId);
                        }
                    } else {
                        this.$Message.info(res.message);
                    }
                });
            },
            checkPayStatus(id) {
                get(`${baseUrl}/v1/order/detail/${id}`).then((res) => {
                    if (res.code === 0) {
                        if (res.result.state === "EXAMINING") {
                            clearInterval(this.checkOrderTimer);
                            this.nowIndex = 1;
                            this.$parent.changeHeight();
                            scrollTo(0, 0);
                            this.payModal = false;
                            this.orderId = res.result.id;
                            this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
                        }
                    }
                });
            },
            loopCheckOrder(id) {
                this.checkOrderTimer = setInterval(() => {
                    this.checkPayStatus(id);
                }, 5000);
            },

            // 列表
            async getServiceOrder() {
                const json = await getPatentOrderList(this.params);
                this.dataList = json.result.list;
                this.dataList.forEach((item, index) => {
                    item.goodsName = item.goods[0].goodsName;
                    item.price = "¥" + item.goods[0].price;
                    item.updatedTime = item.updatedTime.split(" ")[0];
                });
                this.total = json.result.total;
                this.$parent.changeHeight();
            },
            async submit() {
                this.detailComment.orderNo = this.commentItem.no;
                let json = await saveComment(this.detailComment);
                if (json.code == 0) {
                    for (let key in this.detailComment) {
                        if (key != "evaluateContent") {
                            this.detailComment[key] = 0;
                        } else {
                            this.detailComment[key] = "";
                        }
                    }
                    successToast("评价成功");
                    this.commentModal = false;
                    this.getServiceOrder();
                } else {
                    errorToast(json.message);
                }
            },
            //评价
            comment(row) {
                this.commentItem = row;
                this.commentModal = true;
            },
            confirmDel() {
                this.commentModal = false;
            },
            //查看详情
            seeDetail(row) {
                this.detailRow = row;
                get(`${baseUrl}/v1/order/detail/${row.id}`).then((res) => {
                    let item = res.result;
                    this.detailItem = res.result;
                    if (!this.detailItem.orderEvaluate) {
                        this.detailItem.orderEvaluate = {};
                        this.detailItem.orderEvaluate.serviceQuality = 0;
                        this.detailItem.orderEvaluate.serviceSpeed = 0;
                        this.detailItem.orderEvaluate.serviceAttitude = 0;
                    }
                    if (this.detailItem.businessBank || this.detailItem.businessBank == "") {
                        this.qrOrSelf = true;
                    } else {
                        this.qrOrSelf = false;
                    }
                    let detailData = {};
                    detailData.shopName = item.shopName;
                    detailData.goods = item.goods[0].goodsName;
                    detailData.goodsClassify = item.goods[0].goodsClassify;
                    detailData.price = "¥" + item.goods[0].price;
                    detailData.serviceCharge = "¥" + item.goods[0].serviceCharge;
                    detailData.goodsSpec = item.goods[0].goodsSpec;
                    detailData.totalPrice =
                        "¥" + (Number(item.goods[0].price) + Number(item.goods[0].serviceCharge));
                    this.detailData.push(detailData);
                    // this.$router.push({
                    //   path: "/enterprise/enterprise-management-center",
                    //   query: {
                    //     id: this.$route.query.id,
                    //   },
                    // });
                    scrollTo(0, 0);
                    this.nowIndex = 2;

                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    scrollTo(0, 0);
                    this.$parent.changeHeight();
                });
            }
        },
        mounted() {
            if (this.$route.query.payStatus) {
                this.nowIndex = this.$route.query.payStatus;
                this.id = this.$route.query.orderId;
                get(`${baseUrl}/v1/order/detail/${this.id}`).then((res) => {
                    if (res.code == 0) {
                        this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
                        this.orderId = res.result.no;
                        this.orderNo = res.result.no;
                        this.payTypeTxt = res.result.type;
                    }
                });
            } else if (this.$route.query.orderId) {
                this.id = this.$route.query.orderId;
                this.seeDetail2();
            } else {
                this.nowIndex = 0;
                this.params.orderNo = this.$route.params.orderSn || "";
                this.getServiceOrder();
            }
        }
    };
</script>

<style scoped lang="scss">
    .wxQrcodePay {
        img {
            width: 150px !important;
            height: 150px !important;
        }
    }

    .div_center {
        padding: 20px;
        border-bottom: 2px dotted #dfe0f2;
        margin: 0 20px;
    }


    /deep/ .ivu-table-tbody tr:nth-child(2n) td {
        background-color: #FAFAFA !important;
    }

    /deep/ .ivu-modal-header-inner {
        text-align: center;
        background-color: rgb(245, 246, 250) !important;
    }

    /deep/ .ivu-modal-header {
        background-color: rgb(245, 246, 250) !important;
    }

    /deep/ .ivu-modal-footer {
        display: none !important;
    }

    /deep/ .ivu-rate-text {
        display: none;
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin: 50px 20px;
        overflow: hidden;
        padding-bottom: 10px;
    }

    .right_header {
        padding: 20px;
        margin-bottom: 40px !important;
        border-bottom: 1px solid #e8eaec;
        overflow: hidden;
    }

    .button_style {
        display: inline-block;
        background-color: #0E61DF;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    /deep/ .ivu-input, .ivu-select {
        height: 30px !important;
        line-height: 30px !important;
        background: #FFFFFF;
        border-radius: 4px !important;
    }
</style>
